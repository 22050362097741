<template>
  <footer class="footer">
    <div class="footer-content">
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routes" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in rules" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
        </li>
      </ul>
      <!-- <ul class="footer-content__list">
        <li class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in numbers" :key="index">
          <a :href="route.route">{{ route.name }}</a>
        </li>
      </ul> -->
    </div>
    </footer>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {
      return {
        routes: [
          {
            name       :  'sportsbook',
            route      :  'sportsbook'
          },
          {
            name       :  'live betting',
            route      :  'live-betting'
          },
          {
            name       :  'racebook',
            route      :  'racebook'
          },
          {
            name       :  'casino',
            route      :  'casino'
          }
        ],
        rules: [
          {
            name       :  'house rules',
            route      :  'house-rules'
          },
          {
            name       :  'sports rules',
            route      :  'sports-rules'
          },
          {
            name       :  'horse rules',
            route      :  'horse-rules'
          },
          {
            route      :  'same-game-parlay-rules',
            name        : 'same game parlay rules'
          }
        ]
        // numbers: [
        //   {
        //     name       :  '1-888-232-6194',
        //     route      :  'tel:+1-888-232-6194'
        //   },
        //   {
        //     name       :  '1-877-238-5507',
        //     route      :  'tel:+1-877-238-5507'
        //   },
        //   {
        //     name       :  '1-866-895-4647',
        //     route      :  'tel:+1-866-895-4647'
        //   }
        // ]
      }
    },
  };
</script>
